const MAX_LIKES_PER_SESSION = 5;
const LIKES_STORAGE_KEY = 'profile_badge_likes_count';

export const canLike = () => {
  const likesCount = parseInt(
    localStorage.getItem(LIKES_STORAGE_KEY) || '0',
    10
  );
  return likesCount < MAX_LIKES_PER_SESSION;
};

export const incrementLikeCount = () => {
  const currentCount = parseInt(
    localStorage.getItem(LIKES_STORAGE_KEY) || '0',
    10
  );
  localStorage.setItem(LIKES_STORAGE_KEY, (currentCount + 1).toString());
};

export const getLikeCount = () => {
  return parseInt(localStorage.getItem(LIKES_STORAGE_KEY) || '0', 10);
};
