import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

if (!API_BASE_URL) {
  throw new Error('API_BASE_URL is not set in environment variables');
}

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': API_KEY
  },
  timeout: 10000
});

const handleApiError = (error) => {
  if (error.response) {
    throw new Error(error.response.data.message || 'An error occurred');
  } else if (error.request) {
    throw new Error(
      'We failed to register your like 😢. Please try again later.'
    );
  } else {
    throw new Error('An error occurred. Please try again later.');
  }
};

export const incrementLikes = async () => {
  try {
    const response = await api.post('/feedback/thumbsup');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getTotalLikes = async () => {
  try {
    const response = await api.get('/feedback/totallikes');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const joinWaitingList = async (name, email) => {
  try {
    const response = await api.post('/waitinglist/join', { name, email });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
