import React from 'react';
import {
  User,
  Heart,
  Award,
  Briefcase,
  MessageCircle,
  Type,
  Palette,
  Eye,
  Smartphone,
  RotateCw,
  UserCheck,
  Wand2,
  Lightbulb,
  Rocket
} from 'lucide-react';

const BenefitCard = ({ icon: Icon, text }) => (
  <div className="bg-white dark:bg-gray-700 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col items-center text-center">
    <Icon className="h-12 w-12 text-indigo-500 mb-4" />
    <p className="text-gray-700 dark:text-gray-300">{text}</p>
  </div>
);

const TipCard = ({ icon: Icon, text }) => (
  <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow duration-300">
    <Icon className="h-8 w-8 text-indigo-500 mb-3" />
    <p className="text-gray-700 dark:text-gray-300 text-sm">{text}</p>
  </div>
);

const CreativityCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white dark:bg-gray-700 rounded-lg p-6 shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1">
    <Icon className="h-10 w-10 text-indigo-500 mb-4" />
    <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">
      {title}
    </h3>
    <p className="text-gray-600 dark:text-gray-300">{description}</p>
  </div>
);

const ProfileBadgeSections = () => (
  <>
    <div className="bg-gradient-to-r from-green-50 to-blue-100 dark:from-gray-800 dark:to-blue-900 rounded-lg shadow-lg p-8 mb-16">
      <h2 className="text-3xl font-bold mb-8 text-center text-gray-900 dark:text-white">
        Unleash Your Creativity
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <CreativityCard
          icon={Wand2}
          title="Make a Lasting Impression"
          description="Your profile picture is often the first thing people notice. With our Profile Badge Creator, ensure that first impression is unforgettable."
        />
        <CreativityCard
          icon={Lightbulb}
          title="Express Yourself"
          description="Whether you're promoting a cause, celebrating an achievement, or showcasing your brand, our tool helps you express your unique identity."
        />
        <CreativityCard
          icon={Rocket}
          title="Stand Out from the Crowd"
          description="In a sea of standard profile pictures, a custom badge sets you apart. Be memorable, be unique, be you!"
        />
      </div>
      <div className="mt-8 text-center">
        <p className="text-lg text-gray-700 dark:text-gray-300 mb-4">
          With our intuitive tools, your creativity is the only limit.
          Experiment with colors, text, and positions to find your perfect look.
        </p>
        <a
          href="#create-badge"
          className="inline-block bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
        >
          Start Creating Now!
        </a>
      </div>
    </div>

    <div className="bg-gradient-to-r from-purple-50 to-indigo-100 dark:from-gray-900 dark:to-indigo-900 rounded-lg shadow-lg p-8 mb-16">
      <h2 className="text-3xl font-bold mb-8 text-center text-gray-900 dark:text-white">
        Elevate Your Online Presence
      </h2>

      <div className="mb-12">
        <h3 className="text-2xl font-semibold mb-6 text-center text-gray-800 dark:text-gray-200">
          Why Use a Profile Badge?
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <BenefitCard
            icon={User}
            text="Stand out in crowded social media feeds and profiles"
          />
          <BenefitCard
            icon={Heart}
            text="Show support for causes or movements you believe in"
          />
          <BenefitCard
            icon={Award}
            text="Celebrate personal or professional achievements"
          />
          <BenefitCard
            icon={Briefcase}
            text="Create a cohesive brand identity across different platforms"
          />
          <BenefitCard
            icon={MessageCircle}
            text="Increase engagement and spark conversations with your audience"
          />
          <BenefitCard
            icon={UserCheck}
            text="Build trust and credibility with your followers"
          />
        </div>
      </div>

      <div>
        <h3 className="text-2xl font-semibold mb-6 text-center text-gray-800 dark:text-gray-200">
          Tips for Creating Effective Profile Badges
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <TipCard
            icon={Type}
            text="Keep your text short and impactful - aim for 1-3 words"
          />
          <TipCard
            icon={Palette}
            text="Choose colors that contrast well with your profile picture"
          />
          <TipCard
            icon={Eye}
            text="Ensure text is legible - adjust opacity and position as needed"
          />
          <TipCard
            icon={Briefcase}
            text="Align your badge with your personal or brand identity"
          />
          <TipCard
            icon={RotateCw}
            text="Update your badge regularly to keep your profile fresh"
          />
          <TipCard
            icon={Smartphone}
            text="Test your badge on different devices to ensure it looks good on all screens"
          />
        </div>
      </div>
    </div>
  </>
);

export default ProfileBadgeSections;
