import React, { useEffect, useRef } from 'react';

const OverlayBadge = ({
  image,
  bannerText,
  bannerColor,
  bannerOpacity,
  bannerPosition
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (image) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        const size = Math.min(img.width, img.height);
        canvas.width = size;
        canvas.height = size;

        ctx.save();
        ctx.beginPath();
        ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
        ctx.clip();
        ctx.drawImage(
          img,
          (img.width - size) / 2,
          (img.height - size) / 2,
          size,
          size,
          0,
          0,
          size,
          size
        );
        ctx.restore();

        ctx.save();
        ctx.globalAlpha = bannerOpacity;
        ctx.fillStyle = bannerColor;
        const bannerHeight = size * 0.15;
        const bannerY = size * (1 - bannerPosition) - bannerHeight / 2;
        ctx.fillRect(0, bannerY, size, bannerHeight);
        ctx.restore();

        const fontSize = size * 0.05;

        ctx.font = `bold ${fontSize}px Arial`;
        ctx.fillStyle = '#ffffff';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(bannerText, size / 2, bannerY + bannerHeight / 2);
      };
      img.src = image;
    }
  }, [image, bannerText, bannerColor, bannerOpacity, bannerPosition]);

  return (
    <canvas ref={canvasRef} style={{ maxWidth: '100%', borderRadius: '50%' }} />
  );
};

export default OverlayBadge;
