import React from 'react';

const FAQ = () => {
  const faqs = [
    {
      question: 'How does Profile Badge Creator work?',
      answer:
        'Upload an image, add a customizable banner with text, adjust the color, opacity, and position, then download your new profile badge.'
    },
    {
      question: 'Is my image stored on your servers?',
      answer:
        "No, all processing happens in your browser. We don't store or transmit your images to any server."
    },
    {
      question: 'What image formats are supported?',
      answer: 'We support common image formats including PNG, JPG, and GIF.'
    },
    {
      question: 'Can I use the badges I create commercially?',
      answer:
        "Yes, you're free to use your created badges however you like, including for commercial purposes."
    },
    {
      question: 'Is there a limit to how many badges I can create?',
      answer: 'No, you can create as many badges as you want!'
    },
    {
      question: 'Do I need to create an account to use Profile Badge Creator?',
      answer: 'No account is needed. You can start creating badges right away!'
    }
  ];

  return (
    <div className="max-w-3xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold text-center mb-8 text-gray-900 dark:text-white">
        Frequently Asked Questions
      </h1>
      <div className="space-y-8">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="bg-white dark:bg-gray-800 shadow-sm rounded-lg p-6"
          >
            <h2 className="text-xl font-semibold mb-3 text-gray-900 dark:text-white">
              {faq.question}
            </h2>
            <p className="text-gray-600 dark:text-gray-300">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
