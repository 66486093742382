import React, { useState, useCallback, useEffect } from 'react';
import {
  Upload,
  Palette,
  Shuffle,
  X,
  Linkedin,
  Image,
  Sliders,
  Download,
  Share2,
  ChevronDown,
  ChevronUp,
  Sparkles
} from 'lucide-react';
import { TwitterShareButton, LinkedinShareButton } from 'react-share';
import Canvas from './Canvas';
import OverlayBadge from './OverlayBadge';
import ControlPanel from './ControlPanel';
import ProfileBadgeSections from './ProfileBadgeSections';
import { incrementLikes, getTotalLikes } from '../services/api';
import ErrorAlert from './ErrorAlert';
import { canLike, incrementLikeCount } from '../utils/likesLimit';
import WaitingList from './WaitingList';

const predefinedImages = [
  'image1.jpg',
  'image2.jpg',
  'image3.jpg',
  'image4.jpg',
  'image5.jpg',
  'image6.jpg'
];

const Step = ({ icon: Icon, title, description }) => (
  <div className="flex items-start space-x-3">
    <div className="flex-shrink-0">
      <div className="flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white">
        <Icon className="h-6 w-6" aria-hidden="true" />
      </div>
    </div>
    <div>
      <p className="text-lg font-medium text-gray-900 dark:text-white">
        {title}
      </p>
      <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
        {description}
      </p>
    </div>
  </div>
);

const GlossaryItem = ({ term, definition }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 dark:border-gray-700">
      <button
        className="flex justify-between items-center w-full py-4 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-gray-900 dark:text-white">
          {term}
        </span>
        {isOpen ? (
          <ChevronUp className="h-5 w-5 text-gray-500 dark:text-gray-400" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-500 dark:text-gray-400" />
        )}
      </button>
      {isOpen && (
        <p className="pb-4 text-gray-600 dark:text-gray-300">{definition}</p>
      )}
    </div>
  );
};

const SimplifiedHeader = () => (
  <div className="mb-16 py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-r from-indigo-100 to-purple-100 dark:from-indigo-900 dark:to-purple-900 rounded-lg shadow-md">
    <div className="max-w-4xl mx-auto text-center">
      <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-900 dark:text-white mb-4 leading-tight">
        Create Your Custom{' '}
        <span className="text-indigo-600 dark:text-indigo-400">
          Profile Badge
        </span>
        <Sparkles className="inline-block ml-2 text-yellow-500" size={36} />
      </h1>
      <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
        Stand out on social media with a unique, professional profile badge. No
        design skills required!
      </p>
      <a
        href="#create-badge"
        className="inline-block bg-indigo-600 text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300 ease-in-out hover:bg-indigo-700 hover:shadow-lg"
      >
        Get Started
      </a>
    </div>
  </div>
);

const colorPalettes = [
  ['#FF6B6B', '#4ECDC4', '#45B7D1', '#F7FFF7'],
  ['#2C3E50', '#E74C3C', '#ECF0F1', '#3498DB'],
  ['#FC5185', '#3FC1C9', '#364F6B', '#F5F5F5'],
  ['#00B8A9', '#F8F3D4', '#F6416C', '#FFDE7D']
];

function BannerTool() {
  const [image, setImage] = useState(null);
  const [bannerText, setBannerText] = useState('#SUPERCOOLHUMAN');
  const [bannerColor, setBannerColor] = useState('#BA55D3');
  const [bannerOpacity, setBannerOpacity] = useState(0.5);
  const [bannerPosition, setBannerPosition] = useState(0.15);
  const [badgeType, setBadgeType] = useState('standard');
  const [dragActive, setDragActive] = useState(false);
  const [badgeCreated, setBadgeCreated] = useState(false);
  const [totalLikes, setTotalLikes] = useState(0);
  const [isThumbsUpLoading, setIsThumbsUpLoading] = useState(false);
  const [error, setError] = useState(null);

  const MAX_TEXT_LENGTH = 14;
  const MIN_POSITION = 0;
  const MAX_POSITION_STANDARD = 0.24;
  const MAX_POSITION_OVERLAY = 0.5;

  useEffect(() => {
    fetchTotalLikes();
  }, []);

  useEffect(() => {
    if (badgeType === 'standard' && bannerPosition > MAX_POSITION_STANDARD) {
      setBannerPosition(MAX_POSITION_STANDARD);
    }
    if (badgeType === 'overlay' && bannerPosition > MAX_POSITION_OVERLAY) {
      setBannerPosition(MAX_POSITION_OVERLAY);
    }
  }, [badgeType, bannerPosition]);

  const fetchTotalLikes = async () => {
    try {
      const data = await getTotalLikes();
      setTotalLikes(data.totalLikes);
      setError(null);
    } catch (error) {
      setError('Failed to fetch total likes. Please try again later.');
    }
  };

  const handleThumbsUp = async () => {
    if (!canLike()) {
      setError(
        'Whoa, easy there, enthusiastic liker! 👍✨ Save some thumbs-up for next time!'
      );
      return;
    }

    setIsThumbsUpLoading(true);
    try {
      const { totalLikes } = await incrementLikes();
      setTotalLikes(totalLikes);
      incrementLikeCount();
      setError(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsThumbsUpLoading(false);
    }
  };

  const handleFile = useCallback((file) => {
    if (typeof file === 'string') {
      setImage(file);
    } else if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => setImage(e.target.result);
      reader.onerror = () => alert('Error reading file. Please try again.');
      reader.readAsDataURL(file);
    } else {
      alert('Please select a valid image file.');
    }
  }, []);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (bannerText || bannerColor || image || badgeType) {
      setBadgeCreated(false);
    }
  }, [bannerText, bannerColor, image, badgeType]);

  const generateRandom = () => {
    const randomText =
      '#' + Math.random().toString(36).substring(2, 8).toUpperCase();

    const randomColor =
      '#' +
      Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0');

    const randomImage =
      predefinedImages[Math.floor(Math.random() * predefinedImages.length)];
    const randomImageUrl = `/images/${randomImage}`;

    setBannerText(randomText);
    setBannerColor(randomColor);
    setImage(randomImageUrl);

    setBadgeCreated(false);
  };

  const handleDownload = () => {
    const canvas = document.querySelector('canvas');
    if (canvas) {
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'profile-badge.png';
      link.href = dataUrl;
      link.click();
      setBadgeCreated(true);
    }
  };

  const shareUrl = 'https://profilebadge.me/';
  const shareText =
    'I just created a cool profile badge!🔥🔥 Make your own with Profile Badge. #ProfileBadge. Try it out yourself! 🚀🚀';

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-indigo-100 dark:from-gray-900 dark:to-indigo-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <SimplifiedHeader />

        <div className="mb-16">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
            How It Works
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <Step
              icon={Image}
              title="Upload Your Image"
              description="Start with your favorite profile picture or logo."
            />
            <Step
              icon={Sliders}
              title="Customize Your Badge"
              description="Add text, choose colors, and adjust positioning."
            />
            <Step
              icon={Download}
              title="Download Your Badge"
              description="Get your new profile badge in high quality."
            />
            <Step
              icon={Share2}
              title="Share Your New Look"
              description="Update your profiles and show off your new badge!"
            />
          </div>
        </div>

        <div
          id="create-badge"
          className="bg-white dark:bg-gray-800 shadow-2xl rounded-2xl overflow-hidden mb-16"
        >
          <div className="p-8">
            <h2 className="text-3xl font-bold text-center text-gray-900 dark:text-white mb-10">
              Create Your Profile Badge Now
            </h2>

            {error && (
              <ErrorAlert message={error} onClose={() => setError(null)} />
            )}

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div className="space-y-8">
                <div
                  className={`relative group ${
                    dragActive ? 'border-indigo-600' : 'border-gray-300'
                  } border-2 border-dashed rounded-lg p-12 text-center cursor-pointer transition-all duration-300 ease-in-out hover:border-indigo-500`}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <input
                    id="image-upload"
                    type="file"
                    className="hidden"
                    onChange={handleChange}
                    accept="image/*"
                  />
                  <label htmlFor="image-upload" className="cursor-pointer">
                    <Upload className="mx-auto h-12 w-12 text-gray-400" />
                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                      SVG, PNG, JPG or GIF
                    </p>
                  </label>
                </div>

                <ControlPanel
                  bannerText={bannerText}
                  setBannerText={setBannerText}
                  bannerColor={bannerColor}
                  setBannerColor={setBannerColor}
                  bannerOpacity={bannerOpacity}
                  setBannerOpacity={setBannerOpacity}
                  bannerPosition={bannerPosition}
                  setBannerPosition={setBannerPosition}
                  MAX_TEXT_LENGTH={MAX_TEXT_LENGTH}
                  MIN_POSITION={MIN_POSITION}
                  MAX_POSITION={
                    badgeType === 'standard'
                      ? MAX_POSITION_STANDARD
                      : MAX_POSITION_OVERLAY
                  }
                  badgeType={badgeType}
                  totalLikes={totalLikes}
                  onThumbsUp={handleThumbsUp}
                  isThumbsUpLoading={isThumbsUpLoading}
                />

                <div className="flex flex-wrap gap-4 justify-center">
                  {colorPalettes.map((palette, index) => (
                    <div key={index} className="flex space-x-2">
                      {palette.map((color, colorIndex) => (
                        <button
                          key={colorIndex}
                          className="w-8 h-8 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          style={{ backgroundColor: color }}
                          onClick={() => setBannerColor(color)}
                        />
                      ))}
                    </div>
                  ))}
                </div>

                <button
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateRandom}
                >
                  <Shuffle className="mr-2 h-5 w-5" /> Generate Random
                </button>
              </div>

              <div className="flex flex-col items-center justify-center">
                <div className="mb-6">
                  <label
                    htmlFor="badge-type"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                  >
                    Choose Badge Style
                  </label>
                  <div className="relative">
                    <select
                      id="badge-type"
                      value={badgeType}
                      onChange={(e) => setBadgeType(e.target.value)}
                      className="appearance-none w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-200 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 shadow-sm transition duration-150 ease-in-out"
                    >
                      <option value="standard">Arc Badge</option>
                      <option value="overlay">Overlay Badge</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 dark:text-gray-300">
                      <ChevronDown className="h-5 w-5" />
                    </div>
                  </div>
                </div>

                {image ? (
                  <div className="w-full max-w-sm">
                    <div
                      className="relative rounded-3xl overflow-hidden shadow-lg"
                      style={{ paddingTop: '100%' }}
                    >
                      <div className="absolute inset-0 bg-gray-200 dark:bg-gray-700">
                        {badgeType === 'standard' ? (
                          <Canvas
                            key={image}
                            image={image}
                            bannerText={bannerText}
                            bannerColor={bannerColor}
                            bannerOpacity={bannerOpacity}
                            bannerPosition={bannerPosition}
                          />
                        ) : (
                          <OverlayBadge
                            key={image}
                            image={image}
                            bannerText={bannerText}
                            bannerColor={bannerColor}
                            bannerOpacity={bannerOpacity}
                            bannerPosition={bannerPosition}
                          />
                        )}
                      </div>
                    </div>
                    <button
                      className="mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out"
                      onClick={handleDownload}
                    >
                      Download Badge
                    </button>
                    {badgeCreated && (
                      <div className="mt-6 space-y-4">
                        <p className="text-sm text-gray-600 dark:text-gray-300 text-center">
                          Love your new badge? Share Profile Badge Creator with
                          your friends!
                        </p>
                        <div className="flex justify-center space-x-4">
                          <TwitterShareButton url={shareUrl} title={shareText}>
                            <button className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                              <X size={20} className="mr-2" />
                              Share on X
                            </button>
                          </TwitterShareButton>
                          <LinkedinShareButton url={shareUrl} title={shareText}>
                            <button className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                              <Linkedin size={20} className="mr-2" />
                              Share on LinkedIn
                            </button>
                          </LinkedinShareButton>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="text-center p-12 bg-gray-100 dark:bg-gray-700 rounded-lg">
                    <Palette className="mx-auto h-12 w-12 text-gray-400" />
                    <p className="mt-2 text-lg text-gray-500 dark:text-gray-400">
                      Upload an image to see your badge preview here
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <WaitingList />

        <ProfileBadgeSections />

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">
            Profile Badge Creator Glossary
          </h2>
          <div className="space-y-2">
            <GlossaryItem
              term="Profile Badge"
              definition="A customized graphic element added to a profile picture to enhance its visual appeal or convey additional information."
            />
            <GlossaryItem
              term="Banner"
              definition="In the context of our tool, a banner is a curved text overlay added to your profile picture."
            />
            <GlossaryItem
              term="Opacity"
              definition="The degree of transparency of the banner. A lower opacity makes the banner more see-through."
            />
            <GlossaryItem
              term="Banner Position"
              definition="The location of the banner on your profile picture, adjustable from top to bottom."
            />
            <GlossaryItem
              term="Color Palette"
              definition="A pre-selected set of colors that work well together, provided for easy banner customization."
            />
            <GlossaryItem
              term="Drag and Drop"
              definition="A method of uploading your profile picture by clicking and dragging the image file into the designated area."
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerTool;
