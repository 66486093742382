import React, { useState } from 'react';
import { joinWaitingList } from '../services/api';

const WaitingList = () => {
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email) {
      setError('Both fields are required');
      return;
    }
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    setError('');

    try {
      await joinWaitingList(name, email);
      setSuccess(true);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="my-16 p-8 bg-gradient-to-r from-purple-100 to-indigo-100 dark:from-gray-900 dark:to-indigo-900 rounded-lg shadow-lg text-center">
      <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
        🚀 More Features Coming Soon!
      </h2>
      <p className="text-lg text-gray-700 dark:text-gray-300 mb-8">
        We’re building more amazing features. Want to be the first to know? Join
        our waiting list to stay updated!
      </p>
      <button
        className="bg-indigo-600 text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300 ease-in-out hover:bg-indigo-700 hover:shadow-lg"
        onClick={() => setShowForm(!showForm)}
      >
        Join the Waiting List
      </button>

      {showForm && (
        <div className="mt-8">
          {success ? (
            <p className="text-green-600 text-lg font-semibold">
              🎉 Thank you for joining the waiting list! We’ll keep you updated.
            </p>
          ) : (
            <form
              onSubmit={handleSubmit}
              className="space-y-6 max-w-md mx-auto"
            >
              {error && <p className="text-red-600">{error}</p>}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Name
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  placeholder="Awesome Human"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  placeholder="Awesome.Human@me.com"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default WaitingList;
