import React from 'react';
import { Camera, Shield, Zap, Users } from 'lucide-react';

const Feature = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
    <div className="p-3 bg-indigo-100 dark:bg-indigo-900 rounded-full mb-4">
      <Icon className="h-6 w-6 text-indigo-600 dark:text-indigo-300" />
    </div>
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 dark:text-gray-300 text-center">
      {description}
    </p>
  </div>
);

const About = () => {
  return (
    <div className="max-w-6xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-16">
        <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white sm:text-5xl">
          About Profile Badge Creator
        </h1>
        <p className="mt-4 text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
          Elevate your online presence with custom profile badges that make a
          lasting impression.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
        <Feature
          icon={Camera}
          title="Custom Uploads"
          description="Upload your own images and create unique profile badges tailored to your style."
        />
        <Feature
          icon={Shield}
          title="Privacy First"
          description="All processing happens in your browser. We never store or transmit your images."
        />
        <Feature
          icon={Zap}
          title="Quick & Easy"
          description="Intuitive interface allows you to create professional badges in minutes."
        />
        <Feature
          icon={Users}
          title="Perfect for Teams"
          description="Create consistent branding across your entire organization with ease."
        />
      </div>

      <div className="bg-gray-100 dark:bg-gray-700 rounded-lg shadow-inner p-8">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
          Why Choose Profile Badge Creator?
        </h2>
        <div className="space-y-4 text-gray-600 dark:text-gray-300">
          <p>
            Profile Badge Creator is more than just a tool—it's your partner in
            crafting a compelling online presence. Whether you're a social media
            influencer, a business professional, or simply someone who wants to
            stand out online, our platform provides the perfect solution.
          </p>
          <p>
            With our user-friendly interface, you can upload your own images,
            add customizable banners with text, and fine-tune various aspects
            like color, opacity, and position. The result? Unique, eye-catching
            profile pictures that are perfect for platforms like Twitter,
            LinkedIn, or any other social media site.
          </p>
          <p>
            But what truly sets us apart is our commitment to your privacy and
            data security. All image processing happens right in your browser—we
            never store or transmit your personal information or images. Your
            creations remain yours and yours alone.
          </p>
          <p>
            Whether you're looking to promote a cause, highlight a special
            event, or simply add a personal touch to your online presence,
            Profile Badge Creator has got you covered. Try it out today and give
            your online profiles the professional, polished look they deserve!
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
