import React from 'react';
import { Hash, Palette, Sliders, ThumbsUp } from 'lucide-react';

const ControlPanel = ({
  bannerText,
  setBannerText,
  bannerColor,
  setBannerColor,
  bannerOpacity,
  setBannerOpacity,
  bannerPosition,
  setBannerPosition,
  MAX_TEXT_LENGTH,
  MIN_POSITION,
  badgeType,
  totalLikes,
  onThumbsUp,
  isThumbsUpLoading
}) => {
  const MAX_POSITION = badgeType === 'standard' ? 0.24 : 0.5;

  return (
    <div className="space-y-6">
      <div>
        <label
          htmlFor="banner-text"
          className="block text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          Banner Text (Max {MAX_TEXT_LENGTH} characters)
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Hash className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            id="banner-text"
            value={bannerText}
            maxLength={MAX_TEXT_LENGTH}
            onChange={(e) =>
              setBannerText(e.target.value.slice(0, MAX_TEXT_LENGTH))
            }
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="banner-color"
          className="block text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          Banner Color
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Palette className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              id="banner-color"
              value={bannerColor}
              onChange={(e) => setBannerColor(e.target.value)}
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>
          <input
            type="color"
            value={bannerColor}
            onChange={(e) => setBannerColor(e.target.value)}
            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="banner-opacity"
          className="block text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          Banner Opacity: {bannerOpacity.toFixed(2)}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Sliders className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="range"
            id="banner-opacity"
            min="0.1"
            max="1"
            step="0.01"
            value={bannerOpacity}
            onChange={(e) => setBannerOpacity(Number(e.target.value))}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="banner-position"
          className="block text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          Banner Position: {bannerPosition.toFixed(2)}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Sliders className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="range"
            id="banner-position"
            min={MIN_POSITION}
            max={MAX_POSITION}
            step="0.01"
            value={bannerPosition}
            onChange={(e) => setBannerPosition(Number(e.target.value))}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
      </div>

      <div className="mt-6">
        <div className="flex items-center justify-between bg-gradient-to-r from-indigo-50 to-blue-50 dark:from-indigo-900 dark:to-blue-900 p-4 rounded-lg shadow-sm">
          <div className="flex items-center space-x-4">
            <div className="bg-white dark:bg-gray-800 p-2 rounded-full shadow-md">
              <ThumbsUp className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
            </div>
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-300">
                Total Likes
              </p>
              <p className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">
                {totalLikes.toLocaleString()}
              </p>
            </div>
          </div>
          <button
            onClick={onThumbsUp}
            disabled={isThumbsUpLoading}
            className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 transition-all duration-200 ease-in-out"
          >
            {isThumbsUpLoading ? (
              <>
                <span className="absolute inset-0 h-full w-full flex items-center justify-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
                <span className="opacity-0">Like This Tool</span>
              </>
            ) : (
              <>
                <ThumbsUp className="mr-2 h-4 w-4" />
                Like This Tool
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;
