import React, { useRef, useEffect } from 'react';

const Canvas = ({
  image,
  bannerText,
  bannerColor,
  bannerOpacity,
  bannerPosition
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (image) {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext('2d');
      if (canvas && ctx) {
        const img = new Image();
        img.onload = () => {
          const size = Math.min(img.width, img.height);
          canvas.width = size;
          canvas.height = size;

          // Draw circular image
          ctx.save();
          ctx.beginPath();
          ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
          ctx.clip();
          ctx.drawImage(
            img,
            (img.width - size) / 2,
            (img.height - size) / 2,
            size,
            size,
            0,
            0,
            size,
            size
          );
          ctx.restore();

          // Calculate banner parameters
          const fontSize = size * 0.05;
          ctx.font = `bold ${fontSize}px Arial`;
          const textWidth = ctx.measureText(bannerText).width;
          const bannerLength = Math.min(
            (textWidth / (size * Math.PI)) * 1.2 + 0.1,
            0.5
          );
          const startAngle = Math.PI * 2 * bannerPosition;
          const endAngle = startAngle + Math.PI * 2 * bannerLength;

          // Draw thinner, faded banner arc from the inside
          ctx.save();
          const bannerGradient = ctx.createRadialGradient(
            size / 2,
            size / 2,
            size * 0.35,
            size / 2,
            size / 2,
            size * 0.5
          );
          bannerGradient.addColorStop(0, `${bannerColor}00`);
          bannerGradient.addColorStop(
            0.7,
            `${bannerColor}${Math.round(bannerOpacity * 255)
              .toString(16)
              .padStart(2, '0')}`
          );
          bannerGradient.addColorStop(
            1,
            `${bannerColor}${Math.round(bannerOpacity * 255)
              .toString(16)
              .padStart(2, '0')}`
          );

          ctx.beginPath();
          ctx.arc(size / 2, size / 2, size * 0.5, startAngle, endAngle);
          ctx.lineTo(size / 2, size / 2);
          ctx.closePath();
          ctx.fillStyle = bannerGradient;
          ctx.fill();
          ctx.restore();

          // Reverse the text rendering direction and make sure it stays upright
          ctx.save();
          ctx.font = `bold ${fontSize}px Arial`;
          ctx.fillStyle = 'white';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';

          const textRadius = size * 0.43;
          const textAngle = (endAngle - startAngle) / (bannerText.length + 1);

          for (let i = bannerText.length - 1; i >= 0; i--) {
            const angle = startAngle + textAngle * (bannerText.length - i);
            const x = size / 2 + Math.cos(angle) * textRadius;
            const y = size / 2 + Math.sin(angle) * textRadius;

            ctx.save();
            ctx.translate(x, y);

            // Ensure text remains upright regardless of position
            if (angle > Math.PI && angle < Math.PI * 2) {
              ctx.rotate(angle + Math.PI / 2);
            } else {
              ctx.rotate(angle - Math.PI / 2);
            }

            ctx.fillText(bannerText[i], 0, 0);
            ctx.restore();
          }

          ctx.restore();
        };
        img.src = image;
      }
    }
  }, [image, bannerText, bannerColor, bannerOpacity, bannerPosition]);

  return (
    <canvas ref={canvasRef} style={{ maxWidth: '100%', borderRadius: '50%' }} />
  );
};

export default Canvas;
